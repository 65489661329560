import { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import Head from 'next/head';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import * as Yup from 'yup';

import { Layout as AuthLayout } from '@layouts/auth/layout';

import { paths } from '@paths/index';

import parseResponseError from '@utils/parseResponseError';

const LoginPage = () => {
  const t = useTranslations('LoginPage');
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('errors.validEmail'))
        .max(255)
        .required(t('errors.emailRequired')),
      password: Yup.string().max(255).required(t('errors.passwordRequired')),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await signIn('credentials', {
          email: values.email,
          password: values.password,
          redirect: false,
        }).then(({ ok, error }: any) => {
          if (ok) {
            router.push(paths.dashboard.index);
            return;
          }
          setError(parseResponseError(error));
        });
      } catch (err: any) {
        setError(parseResponseError(error));
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Head>
        <title>Login | Moneya</title>
      </Head>
      <Box
        sx={{
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%',
          }}
        >
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">{t('title')}</Typography>
              <Typography color="text.secondary" variant="body2">
                {t('subtext')} &nbsp;
                <Link
                  component={NextLink}
                  href={paths.register}
                  underline="hover"
                  variant="subtitle2"
                >
                  {t('registerButton')}
                </Link>
              </Typography>
            </Stack>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                {error && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error}
                  </Alert>
                )}
                <TextField
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                  label={t('email.label')}
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  fullWidth
                  label={t('password.label')}
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                size="large"
                sx={{ my: 3 }}
                variant="contained"
              >
                {t('loginButton')}
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Typography color="text.secondary" variant="body2">
                    <Link
                      component={NextLink}
                      href={paths.restore_password}
                      underline="hover"
                      variant="subtitle2"
                    >
                      {t('forgotPassword')}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </div>
        </Box>
      </Box>
    </>
  );
};

LoginPage.getLayout = (page: any) => <AuthLayout>{page}</AuthLayout>;

export default LoginPage;
