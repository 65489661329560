import { GetServerSidePropsContext } from 'next';
import { useTranslations } from 'next-intl';
import { NextSeo } from 'next-seo';

import { Layout as AuthLayout } from '@layouts/auth/layout';

import LoginPage from '@forms/LoginForm';

const Login = () => {
  const t = useTranslations('LoginPage');

  return (
    <>
      <NextSeo title={t('seo-title')} description={t('seo-desc')} />
      <AuthLayout>
        <LoginPage />
      </AuthLayout>
    </>
  );
};

export default Login;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {
      messages: (await import(`../translations/login/${context.locale}.json`)).default,
    },
  };
}
